<template>
    <SimpleTable formclass="pages/City/form" endpoint="api/v2/city" :aditionalColumns="headers">
        <template>
            <h1>Cidades</h1>
            <v-divider class="mb-4" />
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [{ text: "Nome", value: "name", align: "right", sortable: true }],
        };
    },
};
</script>